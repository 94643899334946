import { useMemo, useContext } from 'react';

import { TeamsPageContext } from '@vyce/core/src/contexts';

import { useActions, useTypedSelector, useInviteButton } from 'src/hooks';
import { WidgetModule } from 'src/modules';
import { INTERFACE_PERMISSIONS } from '@vyce/core/src/types';

export const useTeamsDashboardData = () => {
  const { dashboards, first_name } = useTypedSelector(state => state.user);
  const { selectedCompanyAppData, selectedCompany, selectedPosition, mainDashboardFilters } =
    useTypedSelector(state => state.helper);
  const { loading, teams, selectedCompanyId = '' } = useContext(TeamsPageContext);
  const {
    isInviteFormOpen,
    subscribeLoading,
    isActivateSubscriptionDialogOpen,
    simpleSubscribe,
    setCloseInviteForm,
    handleInviteButtonClick,
    closeActivateSubscriptionDialog,
  } = useInviteButton();
  const { updateUserDashboards, updateMainDashboardFilters } = useActions();

  const hasTimePermissions = selectedPosition?.permissions.includes(INTERFACE_PERMISSIONS.TIME);
  const hasTeamPermissions = selectedPosition?.permissions.includes(INTERFACE_PERMISSIONS.TEAMS);

  const contextOptions = useMemo(
    () => ({
      dashboards,
      first_name,
      companyDataLoading: loading,
      teams: teams ?? [],
      selectedCompanyId,
      mainDashboardFilters,
      timePermission: !!hasTimePermissions,
      teamPermission: !!hasTeamPermissions,
      updateMainDashboardFilters,
      updateUserDashboards,
      widgetComponent: WidgetModule,
      onOpenUniversalInviteForm: handleInviteButtonClick,
    }),
    [
      dashboards,
      first_name,
      selectedCompanyAppData,
      selectedCompanyId,
      loading,
      teams,
      mainDashboardFilters,
      hasTeamPermissions,
      hasTimePermissions,
      updateMainDashboardFilters,
      updateUserDashboards,
    ]
  );
  return {
    isActivateSubscriptionDialogOpen,
    subscribeLoading,
    contextOptions,
    isInviteFormOpen,
    selectedCompany,
    simpleSubscribe,
    setCloseInviteForm,
    closeActivateSubscriptionDialog,
  };
};
