import { useState, useContext, useEffect } from 'react';

import { Layout, Layouts } from 'react-grid-layout';

import { INTERFACE_PERMISSIONS, UserDashboard, WidgetItem } from '@vyce/core/src/types';

import { DeviceContext } from '@vyce/core/src/contexts';

import { useActions, useTypedSelector, useInviteButton } from 'src/hooks';

export const usePayrollDashboardData = () => {
  const [payDashboard, setPayDashboard] = useState<UserDashboard>();

  const { dashboards, first_name } = useTypedSelector(state => state.user);
  const { selectedCompany, selectedPosition, mainDashboardFilters } = useTypedSelector(
    state => state.helper
  );

  const { updateUserDashboards, updateMainDashboardFilters } = useActions();
  const {
    subscribeLoading,
    isInviteFormOpen,
    isActivateSubscriptionDialogOpen,
    closeActivateSubscriptionDialog,
    handleInviteButtonClick,
    setCloseInviteForm,
    simpleSubscribe,
  } = useInviteButton();
  const { isMobile } = useContext(DeviceContext);

  const hasTimePermissions = selectedPosition?.permissions.includes(INTERFACE_PERMISSIONS.TIME);
  const hasTeamPermissions = selectedPosition?.permissions.includes(INTERFACE_PERMISSIONS.TEAMS);

  const onWidgetStatusChange = (systemName: string) => {
    const dashboardWidgets = payDashboard?.widgets || [];
    const newWidgets: WidgetItem[] = dashboardWidgets.map(item => {
      return item.system_name === systemName ? { ...item, is_active: !item.is_active } : item;
    });
    setPayDashboard(value => ({ ...value, widgets: newWidgets }));
  };

  const updateDashboards = () => {
    if (!payDashboard) {
      return;
    }
    updateUserDashboards({
      ...dashboards,
      pay: payDashboard,
    });
  };

  const resetDashboards = () => {
    if (payDashboard && payDashboard.widgets) {
      const restoredDashboard = payDashboard.widgets.map(item => {
        return { ...item, is_active: true };
      });

      setPayDashboard(value => ({ ...value, widgets: restoredDashboard }));
      updateUserDashboards({
        ...dashboards,
        pay: { ...payDashboard, widgets: restoredDashboard },
      });
    }
  };

  const onLayoutChange = (currentLayout: Layout[], newLayouts: Layouts) => {
    setPayDashboard(value => ({ ...value, layouts: newLayouts }));
  };

  useEffect(() => {
    if (dashboards?.pay) {
      setPayDashboard(dashboards.pay);
    }
  }, [dashboards?.pay]);

  return {
    isMobile,
    first_name,
    loading: subscribeLoading,
    isActivateSubscriptionDialogOpen,
    payDashboard,
    isInviteFormOpen,
    selectedCompany,
    mainDashboardFilters,
    timePermission: !!hasTimePermissions,
    teamPermission: !!hasTeamPermissions,
    updateMainDashboardFilters,
    onLayoutChange,
    onWidgetStatusChange,
    closeActivateSubscriptionDialog,
    simpleSubscribe,
    handleInviteButtonClick,
    setCloseInviteForm,
    updateDashboards,
    resetDashboards,
  };
};
