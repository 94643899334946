import {
  Box,
  Typography,
  Accordion,
  makeStyles,
  AccordionSummary,
  AccordionDetails,
  useTheme,
} from '@material-ui/core';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

import { ModuleVideo } from '@vyce/core/src/components/ModuleVideo';
import { monochrome } from '@vyce/core/src/theme/styles';

interface Props {
  question: {
    title: string;
    answers: string[];
    video_url: string;
  };
  expanded: boolean;
  toggleExpanded: (section: string) => void;
}

export const FAQItem = ({ question, expanded, toggleExpanded }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isDarkMode = theme.palette.type === 'dark';
  const textColor = isDarkMode ? monochrome.light : monochrome.dark;

  return (
    <Accordion classes={{ root: classes.accordion, expanded: classes.expanded }} expanded={expanded}>
      <AccordionSummary onClick={() => toggleExpanded(question.title)}>
        <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
          <Typography
            style={{
              color: expanded && !isDarkMode ? theme.palette.primary.dark : textColor,
            }}
            variant="caption">
            {question.title}
          </Typography>

          <Box display="flex" alignItems="center" justifyContent="center">
            {expanded ? (
              <FiChevronUp size={24} color={theme.palette.primary.dark} />
            ) : (
              <FiChevronDown size={24} color="#A0A3BD" />
            )}
          </Box>
        </Box>
      </AccordionSummary>

      <AccordionDetails>
        <Box marginBottom={2}>
          {!!question.video_url && expanded && (
            <Box display="flex" justifyContent="center" alignItems="center" paddingBottom={2}>
              <ModuleVideo previewUrl={''} url={question.video_url} />
            </Box>
          )}

          <Box display="flex" flexDirection="column" gridGap={8}>
            {question.answers?.map((answer, index) => (
              <Box key={answer} display="flex" gridGap={2}>
                {question.answers.length > 1 && (
                  <Typography style={{ color: textColor, lineHeight: '28px' }} variant="caption">
                    {index + 1}.
                  </Typography>
                )}
                <Typography style={{ color: textColor, lineHeight: '28px' }} variant="caption">
                  {answer}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

const useStyles = makeStyles(theme => ({
  accordion: {
    paddingRight: 16,
    paddingLeft: 16,
  },
  expanded: {
    marginTop: '0 !important',
    backgroundColor: theme.palette.primary.light,
    borderRadius: '8px !important',
  },
}));
