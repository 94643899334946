import React, { useContext, useState } from 'react';

import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { AiOutlinePlus } from 'react-icons/ai';
import { TbPencil } from 'react-icons/tb';
import { RiEyeLine } from 'react-icons/ri';
import { Theme } from '@material-ui/core/styles';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

import { AppTextField } from '@vyce/core/src/components/inputs/AppTextField';
import { Company, COMPANY_PERMISSIONS, CompanyPosition } from '@vyce/core/src/types';
import { AppIconButton } from '@vyce/core/src/components/AppIconButton';
import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';
import { createCompanyWithOwnerRequest } from '@vyce/core/src/api/companies';
import defaultCompanyPicture from '@vyce/core/src/assets/company-icon.png';
import { getLocationDetails } from '@vyce/core/src/utils/location';
import { MAIN_CONTAINER_ID } from '@vyce/core/src/constants';

import { useActions, useTypedSelector } from '../../hooks';
import { CompanyStep } from '../onboarding/employer/steps/CompanyStep';
import useStyles from './styles';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

export const EmployerPositions: React.FC = () => {
  const {
    user,
    helper: { selectedPosition },
  } = useTypedSelector(state => state);
  const { handleServerError, showNotification } = useContext(NotificationContext);
  const classes = useStyles();
  const theme = useTheme<Theme>();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState<boolean>(false);
  const methods = useForm<any>({
    defaultValues: {
      company: undefined,
      role: '',
    },
  });
  const {
    formState: { errors },
    control,
    getValues,
    reset,
  } = methods;
  const { setPosition, me } = useActions();
  const history = useHistory();
  const { isMobile } = useContext(DeviceContext);

  const addPosition = async () => {
    try {
      const formData = getValues();
      await createCompanyWithOwnerRequest({
        role: formData.role,
        company: {
          name: formData.company.name,
          reg_number: formData.company.registration_number,
          status: formData.company.status,
          address: formData.company.address,
        },
      });
      me();
      showNotification({ message: 'Position has been created', options: { variant: 'success' } });
      reset();
      setOpen(false);
    } catch (e: any) {
      handleServerError(e);
      if (e?.response?.data?.detail) {
        reset();
        setOpen(false);
      }
    }
  };

  const select = (position: CompanyPosition) => {
    setPosition(position);
  };

  const goToCompany = (company: Company, path: string = '') => {
    if (!company?.uuid) {
      return;
    }
    history.push(`/companies/${company.name}_${company.uuid}/${path}`);
  };

  return (
    <Box padding={isMobile ? 1 : 5} paddingTop={isMobile ? 3 : 5}>
      <Box className={classes.wrapper}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography className={classes.bold} variant="h6">
            My Companies
          </Typography>

          <Button
            onClick={() => setOpen(true)}
            startIcon={<AiOutlinePlus />}
            variant="contained"
            color="primary">
            Add Position
          </Button>
        </Box>

        {user?.positions?.map(position => (
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection={isMobile ? 'column' : 'row'}
            alignItems={isMobile ? 'flex-start' : 'center'}
            key={position.company?.uuid}
            marginTop={2}
            className={clsx(classes.position, {
              [classes.selectedPosition]: selectedPosition?.company?.uuid === position.company?.uuid,
            })}>
            <Box display="flex" alignItems="center">
              <Avatar src={position.company.logo?.url}>
                {!position.company.logo && <img height={40} alt="company" src={defaultCompanyPicture} />}
              </Avatar>
              <Box marginLeft={2}>
                <Typography>{position.company.name}</Typography>
                <Typography variant="caption">{position.role as string}</Typography>
              </Box>
            </Box>

            <Box
              display="flex"
              alignItems="center"
              marginTop={isMobile ? 1 : 0}
              width={isMobile ? '100%' : 'auto'}>
              {position.permissions.includes(COMPANY_PERMISSIONS.CAN_EDIT) && (
                <AppIconButton onClick={() => goToCompany(position?.company, 'general')} variant="secondary">
                  <TbPencil color={theme.palette.secondary.dark} size="18px" />
                </AppIconButton>
              )}
              <Box marginLeft={1} marginRight={1}>
                <AppIconButton variant="primary" onClick={() => goToCompany(position?.company)}>
                  <RiEyeLine color={theme.palette.primary.dark} size="18px" />
                </AppIconButton>
              </Box>

              <Button
                style={{ height: '45px' }}
                onClick={() => select(position)}
                size="small"
                fullWidth
                color="secondary"
                variant="contained">
                Select
              </Button>
            </Box>
          </Box>
        ))}
      </Box>

      <Dialog
        container={document.getElementById(MAIN_CONTAINER_ID)}
        fullScreen={fullScreen}
        open={open}
        onClose={() => setOpen(false)}>
        <DialogTitle id="responsive-dialog-title">Add new company</DialogTitle>
        <DialogContent>
          <Box width={isMobile ? '100%' : 500}>
            <FormProvider {...methods}>
              <form>
                <Controller
                  control={control}
                  rules={{ required: 'Job Title is required' }}
                  name="role"
                  render={({ field }) => (
                    <AppTextField
                      {...field}
                      error={!!errors.role?.message}
                      id="job-title"
                      label="Job Title"
                      margin="normal"
                      fullWidth
                      helperText={(errors.role?.message as string) || ''}
                    />
                  )}
                />

                <CompanyStep />
              </form>
            </FormProvider>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button size="large" onClick={() => setOpen(false)} variant="outlined">
            Cancel
          </Button>

          <Button size="large" onClick={addPosition} variant="contained" color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
