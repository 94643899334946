import React, { useContext, useEffect, useState } from 'react';

import { Layout, Layouts } from 'react-grid-layout';
import { Box, LinearProgress, Typography, Button } from '@material-ui/core';
import { AiOutlinePlus } from 'react-icons/ai';
import clsx from 'clsx';

import { INTERFACE_PERMISSIONS, WidgetItem } from '@vyce/core/src/types';
import { AppDashboard, getInitialLayouts } from '@vyce/core/src/components/Dashboard/AppDashboard';
import { CustomizeDashboard, ActivateSubscriptionDialog } from '@vyce/core/src/components';
import { DeviceContext } from '@vyce/core/src/contexts';
import { WeatherWidget } from '@vyce/core/src/views/widgets/WeatherWidget';
import { getPaddingForContent } from '@vyce/core/src/utils';
import { useHorizontalScrollStyles } from '@vyce/core/src/styles';
import { DayQuoteWidget } from '@vyce/core/src/views/widgets/DayQuoteWidget';

import { useTypedSelector, useInviteButton, useActions } from 'src/hooks';
import { WidgetModule, UniversalInviteModule } from 'src/modules';
import { WelcomeWidget } from './WelcomeWidget';
import { getFromLS, saveToLS } from '@vyce/core/src/utils/local-storage';

const welcomeWidget: WidgetItem = {
  name: 'Welcome to Vyce!',
  title_color: 'primary',
  type: 'welcome',
  system_name: 'welcome',
  layout: { i: 'welcome', x: 0, y: 0, w: 4, h: 8, minH: 3, minW: 2, isResizable: true },
  is_active: true,
};

const weatherWidget: WidgetItem = {
  name: 'Weather',
  type: 'weather',
  system_name: 'weather',
  layout: { i: 'weather', x: 4, y: 0, w: 4, h: 8, minH: 3, minW: 3, isResizable: true },
  is_active: true,
};

export const DASHBOARD_ITEMS: WidgetItem[] = [welcomeWidget, weatherWidget];
// TODO: Find api or create proxy
// const quoteWidget: WidgetItem = {
//   name: 'Quote of the day',
//   type: 'quote',
//   system_name: 'quote',
//   layout: { i: 'quote', x: 0, y: 9, w: 8, h: 8, minH: 3, minW: 3 },
//   is_active: true,
// };

const LS_ITEMS_KEY = 'generalDashboardItems';
const LS_LAYOUT_KEY = 'generalDashboardLayouts';

export const EmployerDashboard: React.FC = () => {
  const { isMobile } = useContext(DeviceContext);
  const { first_name } = useTypedSelector(state => state.user);
  const { selectedCompany, selectedPosition, selectedCompanyAppData, mainDashboardFilters } =
    useTypedSelector(state => state.helper);
  const { updateMainDashboardFilters } = useActions();

  const [layouts, setLayouts] = useState<Layouts>(
    getFromLS(LS_LAYOUT_KEY) || getInitialLayouts(DASHBOARD_ITEMS)
  );
  const [dashboard, setDashboard] = useState<WidgetItem[]>([]);
  const [isTeamCreated, setIsTeamCreated] = useState<boolean>(true);
  const [isTimeRegistered, setIsTimeRegistered] = useState<boolean>(true);
  const [isPayrollRegistered, setIsPayrollRegistered] = useState<boolean>(false);
  const horizontalScrollClasses = useHorizontalScrollStyles();
  const {
    isActivateSubscriptionDialogOpen,
    isInviteFormOpen,
    subscribeLoading,
    closeActivateSubscriptionDialog,
    setCloseInviteForm,
    handleInviteButtonClick,
    simpleSubscribe,
  } = useInviteButton();
  const { teams, paySchedules, isJobPosted, companyDataLoading, locations } = selectedCompanyAppData || {};
  const { permissions = [] } = selectedPosition || {};
  const padding = getPaddingForContent(isMobile);

  const hasTimePermissions = selectedPosition?.permissions.includes(INTERFACE_PERMISSIONS.TIME);
  const hasTeamPermissions = selectedPosition?.permissions.includes(INTERFACE_PERMISSIONS.TEAMS);

  const getWelcomeData = () => {
    const payrollRegistered = !!selectedCompany?.pay_company;
    const timeRegistered = !!locations?.length;
    const teamCreated = !!teams?.length;
    setIsPayrollRegistered(payrollRegistered);
    setIsTeamCreated(teamCreated);
    setIsTimeRegistered(timeRegistered);
    const LSWidgets = getFromLS(LS_ITEMS_KEY);
    if (LSWidgets) {
      setDashboard(LSWidgets);
      return;
    }

    const permissions = selectedPosition?.permissions || [];
    const isPayrollPermissions =
      permissions.includes(INTERFACE_PERMISSIONS.PAYROLL) ||
      permissions.includes(INTERFACE_PERMISSIONS.PAYROLL_OUTSOURCED);
    const isConnectPermissions = permissions.includes(INTERFACE_PERMISSIONS.CONNECT);
    const isTeamsPermissions = permissions.includes(INTERFACE_PERMISSIONS.TEAMS);

    const widgets = [
      {
        ...welcomeWidget,
        is_active: isPayrollPermissions || isConnectPermissions || isTeamsPermissions,
      },
      weatherWidget,
    ];
    setDashboard(widgets);
    saveToLS(LS_ITEMS_KEY, widgets);
  };

  useEffect(() => {
    if (selectedCompany) {
      getWelcomeData();
    }
  }, [selectedCompany, teams, paySchedules, isJobPosted]);

  const onWidgetStatusChange = (systemName: string) => {
    const newWidgets: WidgetItem[] = dashboard.map(item =>
      item.system_name === systemName ? { ...item, is_active: !item.is_active } : item
    );
    setDashboard(newWidgets);
  };

  const updateDashboards = () => {
    if (!dashboard) {
      return;
    }
    saveToLS(LS_ITEMS_KEY, dashboard);
  };

  const resetDashboards = () => {
    if (dashboard) {
      const restoredDashboard = dashboard.map(item => {
        return { ...item, is_active: true };
      });
      setDashboard(restoredDashboard);
      saveToLS(LS_ITEMS_KEY, restoredDashboard);
    }
  };

  const getWidget = (widget: WidgetItem) => {
    switch (widget.system_name) {
      case 'welcome':
        return {
          component: WelcomeWidget,
          props: { isJobPosted, isPayrollRegistered, isTeamCreated, isTimeRegistered, permissions },
        };
      case 'weather':
        return { component: WeatherWidget, props: {} };
      case 'quote':
        return { component: DayQuoteWidget, props: {} };

      default:
        return { component: WidgetModule, props: { widget, mainDashboardFilters } };
    }
  };

  const onLayoutChange = (currentLayout: Layout[], newLayouts: Layouts) => {
    setLayouts(newLayouts);
    saveToLS(LS_LAYOUT_KEY, newLayouts);
  };

  if (companyDataLoading) {
    return <LinearProgress />;
  }

  return (
    <Box padding={padding}>
      <Box
        className={clsx(
          horizontalScrollClasses.blockWrapper,
          horizontalScrollClasses.blockWithHideScroll,
          horizontalScrollClasses.navBarBlock
        )}>
        <Box display="flex">
          {!isMobile && (
            <>
              <Typography
                variant="subtitle1"
                style={{ marginRight: 4, fontWeight: 600, whiteSpace: 'nowrap' }}
                color="secondary">
                Hey {first_name},
              </Typography>
              <Typography variant="subtitle1" color="textSecondary" style={{ whiteSpace: 'nowrap' }}>
                here’s your dashboard!
              </Typography>
            </>
          )}
        </Box>

        <Box display="flex" gridGap={16}>
          <Button
            onClick={handleInviteButtonClick}
            size="small"
            data-tour="invite"
            startIcon={<AiOutlinePlus />}
            variant="contained"
            color="primary">
            Invite New Members
          </Button>
          <CustomizeDashboard
            items={dashboard}
            companyId={selectedCompany?.uuid ?? ''}
            teamPermission={!!hasTeamPermissions}
            timePermission={!!hasTimePermissions}
            mainDashboardFiltersFromState={mainDashboardFilters}
            updateMainDashboardFilters={updateMainDashboardFilters}
            onWidgetStatusChange={onWidgetStatusChange}
            onUpdateDashboard={updateDashboards}
            resetDashboards={resetDashboards}
          />
        </Box>
      </Box>

      <Box style={{ marginRight: '-24px', marginLeft: '-24px' }}>
        <AppDashboard
          layouts={layouts}
          onLayoutChange={onLayoutChange}
          widgets={dashboard}
          getWidget={getWidget}
          onWidgetStatusChange={onWidgetStatusChange}
        />
      </Box>

      <UniversalInviteModule isOpen={isInviteFormOpen} onClose={setCloseInviteForm} />

      <ActivateSubscriptionDialog
        open={isActivateSubscriptionDialogOpen}
        onClose={closeActivateSubscriptionDialog}
        callback={simpleSubscribe}
        loading={subscribeLoading}
        wasSubscribed={selectedCompany?.subscribed_company}
      />
    </Box>
  );
};
