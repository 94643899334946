import { INTERFACE_PERMISSIONS } from '@vyce/core/src/types';
import { useMemo } from 'react';

import { useActions, useTypedSelector, useInviteButton } from 'src/hooks';
import { WidgetModule } from 'src/modules';

export const useTimeDashboardData = () => {
  const { dashboards, first_name } = useTypedSelector(state => state.user);
  const { selectedCompanyAppData, selectedCompany, selectedPosition, mainDashboardFilters } =
    useTypedSelector(state => state.helper);
  const {
    isInviteFormOpen,
    subscribeLoading,
    isActivateSubscriptionDialogOpen,
    simpleSubscribe,
    setCloseInviteForm,
    handleInviteButtonClick,
    closeActivateSubscriptionDialog,
  } = useInviteButton();
  const { updateUserDashboards, updateMainDashboardFilters } = useActions();

  const hasTimePermissions = selectedPosition?.permissions.includes(INTERFACE_PERMISSIONS.TIME);
  const hasTeamPermissions = selectedPosition?.permissions.includes(INTERFACE_PERMISSIONS.TEAMS);

  const contextOptions = useMemo(
    () => ({
      dashboards,
      name: first_name,
      selectedCompanyId: selectedCompany?.uuid ?? '',
      permissions: selectedPosition?.permissions ?? [],
      mainDashboardFilters,
      timePermission: !!hasTimePermissions,
      teamPermission: !!hasTeamPermissions,
      updateMainDashboardFilters,
      updateUserDashboards,
      widgetComponent: WidgetModule,
      onOpenUniversalInviteForm: handleInviteButtonClick,
    }),
    [
      dashboards,
      first_name,
      selectedCompanyAppData,
      selectedCompany?.uuid,
      selectedPosition?.permissions,
      mainDashboardFilters,
      updateMainDashboardFilters,
      updateUserDashboards,
    ]
  );
  return {
    isActivateSubscriptionDialogOpen,
    subscribeLoading,
    contextOptions,
    isInviteFormOpen,
    selectedCompany,
    simpleSubscribe,
    setCloseInviteForm,
    closeActivateSubscriptionDialog,
  };
};
