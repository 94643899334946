import { Dispatch } from 'redux';

import {
  CompanyPosition,
  INTERFACE_PERMISSIONS,
  LocationAndTeamsFilterState,
  NavItem,
  Shift,
  Subscription,
  Team,
  TimeAndAttendanceLocation,
  User,
} from '@vyce/core/src/types';
import { getSitesRequest } from '@vyce/core/src/api/time';
import { siteConverter } from '@vyce/core/src/utils/location';
import { saveToLS } from '@vyce/core/src/utils/local-storage';
import { GetCompanyTeamsDataRequest, TeamData } from '@vyce/core/src/api/types';
import { getCompanyTeamsRequest } from '@vyce/core/src/api/teams';
import { getPaySchedulesForInvitesRequest, getPaySchedulesRequest } from '@vyce/core/src/api/pay';
import { getCompanyJobsRequest } from '@vyce/core/src/api/connect';
import { getSubscriptionDataRequest, getSubscriptionStatusRequest } from '@vyce/core/src/api/billing';

import { EMPLOYEE_NAV_ITEMS, EMPLOYER_NAV_ITEMS } from '../../views/main/constants';
import { UserAction } from '../actions/user';
import { store } from '../store';
import { ActionType } from '../action-types';
import { HelperAction } from '../actions/helper';

const getTeamsRequestData: GetCompanyTeamsDataRequest = {
  offset: 0,
  limit: 1000,
  order_by: [
    {
      field_name: 'name',
      desc: true,
    },
  ],
};

const generateEmployerNavItems = (position: CompanyPosition): NavItem[] => {
  return EMPLOYER_NAV_ITEMS.filter(item => {
    if (item.permissions?.length) {
      let status = false;
      item.permissions.forEach(permissionName => {
        if (position.permissions.includes(permissionName as INTERFACE_PERMISSIONS)) {
          status = true;
        }
      });
      return status;
    }
    return true;
  });
};

export const isUserEmployee = (user: User) => {
  return user.uuid && user.employee;
};

export const updateNavItems = (
  user: User,
  dispatch: Dispatch<HelperAction>,
  selectedPosition?: CompanyPosition
) => {
  let navItems: NavItem[] = [];

  if (isUserEmployee(user)) {
    navItems = EMPLOYEE_NAV_ITEMS;
  }

  if (user.uuid && user?.positions?.length) {
    const position = selectedPosition || user.positions[0];
    navItems = generateEmployerNavItems(position);
  }

  dispatch({
    type: ActionType.SET_NAV_ITEMS,
    payload: navItems,
  });
};

export const setLoading = (loading: boolean) => {
  return (dispatch: Dispatch<HelperAction>) => {
    dispatch({
      type: ActionType.SET_USER_DATA_LOADING,
      payload: loading,
    });
  };
};

export const clearRedirectTo = () => {
  return (dispatch: Dispatch<HelperAction>) => {
    dispatch({
      type: ActionType.CLEAR_REDIRECT_TO,
    });
  };
};

export const setNavItems = (navItems: NavItem[]) => {
  return (dispatch: Dispatch<HelperAction>) => {
    dispatch({
      type: ActionType.SET_NAV_ITEMS,
      payload: navItems,
    });
  };
};

export const setAccessToken = (token: string) => {
  return (dispatch: Dispatch<HelperAction>) => {
    dispatch({
      type: ActionType.SET_ACCESS_TOKEN,
      payload: token,
    });
  };
};

export const clearHelpers = () => {
  return (dispatch: Dispatch<HelperAction>) => {
    dispatch({
      type: ActionType.CLEAR_HELPERS,
    });
  };
};

export const setShowTour = (status: boolean) => {
  return (dispatch: Dispatch<HelperAction>) => {
    dispatch({
      type: ActionType.SET_SHOW_TOUR,
      payload: status,
    });
  };
};

export const updateSelectedCompanySubscription = (subscription: Subscription) => {
  return (dispatch: Dispatch<HelperAction>) => {
    dispatch({
      type: ActionType.SET_SELECTED_COMPANY_SUBSCRIPTION_DATA,
      payload: subscription,
    });
  };
};

export const setPosition = (position: CompanyPosition) => {
  saveToLS('selectedPosition', { role: position.role, companyId: position.company?.uuid });
  return async (dispatch: Dispatch<UserAction | HelperAction>) => {
    if (!position.company.uuid) {
      return;
    }
    const user = store.getState().user;
    dispatch({
      type: ActionType.SET_SELECTED_COMPANY,
      payload: position.company,
    });
    dispatch({
      type: ActionType.SET_SELECTED_POSITION,
      payload: position,
    });
    updateNavItems(user, dispatch, position);
  };
};

export const fetchCompanyData = (companyId: string) => {
  return async (dispatch: Dispatch<HelperAction>) => {
    dispatch({
      type: ActionType.SET_COMPANY_DATA_LOADING,
      payload: true,
    });
    let token = store.getState().helper.access_token;
    let teams: TeamData[] = [];
    let paySchedules = [];
    let locations: TimeAndAttendanceLocation[] = [];
    let shifts: Shift[] = [];
    let isJobPosted = false;
    let subscription;
    try {
      const resTeams = await getCompanyTeamsRequest({ companyId, requestData: getTeamsRequestData });
      teams = resTeams.data.items;
    } catch (e) {
      console.error(e);
    }
    try {
      const resTime = await getSitesRequest(companyId, {
        order_by: [],
        limit: 1000,
        show_deleted: false,
        only_active: true,
      });
      const converterRes = siteConverter(resTime.data.items);
      locations = converterRes.locations;
      shifts = converterRes.shifts;
    } catch (e) {
      console.error(e);
    }
    try {
      const resPay = await getPaySchedulesForInvitesRequest({ token, companyId, limit: 1000 });
      paySchedules = resPay.data.items;
    } catch (e) {
      console.error(e);
    }
    try {
      const resJobs = await getCompanyJobsRequest(token, {}, companyId, 1, 0);
      isJobPosted = !!resJobs.data.count;
    } catch (e) {
      console.error(e);
    }
    try {
      const resSubscription = await getSubscriptionDataRequest({ token, companyId });
      subscription = resSubscription.data;
    } catch (e) {
      console.error(e);
    }

    if (!subscription) {
      const resSubscriptionStatus = await getSubscriptionStatusRequest({ token, companyId });
      subscription = { active: resSubscriptionStatus.data.status };
    }

    dispatch({
      type: ActionType.SET_COMPANY_DATA,
      payload: {
        teams,
        paySchedules,
        isJobPosted,
        subscription,
        locations,
        shifts,
      },
    });
    dispatch({
      type: ActionType.SET_COMPANY_DATA_LOADING,
      payload: false,
    });
    dispatch({
      type: ActionType.SET_COMPANY_DATA_LOADING,
      payload: false,
    });
  };
};

export const fetchPaySchedules = (companyId: string) => {
  return async (dispatch: Dispatch<HelperAction>) => {
    try {
      const resPay = await getPaySchedulesRequest({ companyId, limit: 1000 });
      dispatch({
        type: ActionType.SET_PAY_SCHEDULES,
        payload: resPay.data.items,
      });
    } catch (e) {
      console.error(e);
    }
  };
};

export const setTeams = (teams: Team[]) => {
  return (dispatch: Dispatch<HelperAction>) => {
    dispatch({
      type: ActionType.SET_TEAMS,
      payload: teams,
    });
  };
};

export const addTeam = (team: Team) => {
  return (dispatch: Dispatch<HelperAction>) => {
    dispatch({
      type: ActionType.ADD_TEAM,
      payload: team,
    });
  };
};

export const setIsJobPostedStatus = () => {
  return (dispatch: Dispatch<HelperAction>) => {
    dispatch({
      type: ActionType.SET_IS_JOB_POSTED_STATUS,
    });
  };
};

export const updateMainDashboardFilters = (filters: LocationAndTeamsFilterState) => {
  return (dispatch: Dispatch<HelperAction>) => {
    dispatch({
      type: ActionType.UPDATE_MAIN_DASHBOARD_FILTERS,
      payload: filters,
    });
  };
};
