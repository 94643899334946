import { useContext, useEffect, useMemo, useState } from 'react';

import { Box, Typography, makeStyles, useTheme } from '@material-ui/core';
import axios from 'axios';
import { HiOutlineQuestionMarkCircle } from 'react-icons/hi';
import { FiUsers } from 'react-icons/fi';
import { WiTime4 } from 'react-icons/wi';
import { MdOutlinePayments } from 'react-icons/md';

import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';

import { FAQItem } from './components/FAQItem';

interface Props {
  faqUrl: string;
}

export const HelpAndContact = ({ faqUrl }: Props) => {
  const { isMobile } = useContext(DeviceContext);
  const classes = useStyles();
  const theme = useTheme();
  const [expandedSections, setExpandedSections] = useState<string[]>([]);
  const [faqs, setFaqs] = useState<any[]>([]);

  const evenFaqs = useMemo(() => faqs.filter((_, index) => index % 2 === 0), [faqs]);
  const oddFaqs = useMemo(() => faqs.filter((_, index) => index % 2 !== 0), [faqs]);

  const getFaqs = async () => {
    try {
      const response = await axios.get(faqUrl);
      setFaqs(response.data.faqs);
    } catch (error) {
      console.error(error);
    }
  };

  const toggleExpanded = (section: string) => {
    setExpandedSections(prev => {
      if (prev.includes(section)) {
        return prev.filter(item => item !== section);
      }
      return [...prev, section];
    });
  };

  const getIcon = (title: string) => {
    const color = theme.palette.primary.main;
    const iconSize = 21;
    if (title === 'General') {
      return <HiOutlineQuestionMarkCircle size={iconSize} color={color} />;
    }
    if (title === 'Teams') {
      return <FiUsers size={iconSize} color={color} />;
    }
    if (title === 'Time & Attendance') {
      return <WiTime4 size={iconSize} color={color} />;
    }
    if (title === 'Payroll') {
      return <MdOutlinePayments size={iconSize} color={color} />;
    }
  };

  useEffect(() => {
    getFaqs();
  }, []);

  return (
    <Box height="100%" position="relative" padding={isMobile ? 1 : 5} paddingTop={isMobile ? 3 : 5}>
      <Typography variant="h6">FAQs</Typography>

      <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} gridGap={16}>
        <Box marginTop={2} className={classes.container} gridGap={8}>
          {evenFaqs.map(item => (
            <Box className={classes.accordionWrapper} key={item.title}>
              <Box paddingLeft={2} display="flex" alignItems="center" gridGap={8}>
                {getIcon(item.title)}
                <Typography variant="subtitle2">{item.title}</Typography>
              </Box>

              {item.questions?.map((question: any) => {
                const expanded = expandedSections.includes(question.title);
                return <FAQItem question={question} expanded={expanded} toggleExpanded={toggleExpanded} />;
              })}
            </Box>
          ))}
        </Box>

        <Box
          marginTop={2}
          flexDirection={isMobile ? 'column' : 'row'}
          className={classes.container}
          gridGap={8}>
          {oddFaqs.map(item => (
            <Box className={classes.accordionWrapper} key={item.title}>
              <Box paddingLeft={2} display="flex" alignItems="center" gridGap={8}>
                {getIcon(item.title)}
                <Typography variant="subtitle2">{item.title}</Typography>
              </Box>

              {item.questions?.map((question: any) => {
                const expanded = expandedSections.includes(question.title);
                return <FAQItem question={question} expanded={expanded} toggleExpanded={toggleExpanded} />;
              })}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    gap: 16,
    flexDirection: 'column',
    flex: 1,
  },
  accordionWrapper: {
    borderRadius: 8,
    backgroundColor: theme.palette.background.paper,
    padding: 8,
    paddingTop: 24,
    height: 'fit-content',
  },
}));
